import React from 'react';

import './style.scss';
import ImgBuilder from "../../res/img/ImgBuilder";

const Footer = () => {

  return (
        <footer className='footer' id='contacts'>
          <h2 className='motto_footer'>Our core values: Probity, Reliability, Proactivity and Humility</h2>
          <div className='main_footer'>

            <div className='contacts_footer'>
              <h2 className='h2__contacts_footer'>Our Contacts:</h2>
              <div className='numbers__contacts_footer'>
                <p className='contact__contacts_footer '>phone: <a className='a_contact__contacts_footer'  href="tel:/+380964029670">+380 96 4029-670</a></p>
                <p className='contact__contacts_footer m_left20'>phone: <a className='a_contact__contacts_footer'  href="tel:/+380506199632">+380 50 6199-632</a></p>
              </div>
              <p className='contact__contacts_footer'>email: <a className='a_contact__contacts_footer' href="mailto:chervinskao@ukr.net">chervinskao@ukr.net</a></p>
            </div>

            <div className='adress_contacts_footer'>
              <h2 className='h2__contacts_footer'>We are located in Lviv</h2>
              <p className='contact__contacts_footer'>30, Hipsova street, Lviv 79057, Ukraine</p>
              <a href='https://goo.gl/maps/311mCpxTBSmh9TFw5' className='contact__contacts_footer a'>Open Map</a>
            </div>
          </div>
          <a href="#main" className='motto_footer back_to_top'>
            Back to top
            <img src={ImgBuilder.Arrow} className='back_to_top_img'  alt=" " />  
          </a>
          <div className='footer_bottom'>
          <p className='p__footer_bottom'>All rights reserved @ 2023 Trade House Ekopro LLC</p>
          </div>
        </footer>
  )
};
export default Footer;