import React from 'react';

import './style.scss';
import ImgBuilder from "../../res/img/ImgBuilder";
import Item_what_we_do from '../item_what_we_do/item_what_we_do';

const What_we_do = () => {

  return (
    <section className='what_we_do'>
        <Item_what_we_do  img={ImgBuilder.Research} text='We do the full range of Import and Distribution services'/>
        <Item_what_we_do  img={ImgBuilder.Graph_circle} text='We execute all aspects of the sales cycle while building and managing relationships with key decision-makers at companies producing food and drinks'/>
        <Item_what_we_do  img={ImgBuilder.Circle} text='We manage and run complex sales deals and guide customers through every aspect of the procurement process from initial contact to close '/>
        <Item_what_we_do  img={ImgBuilder.Sales} text='We prospect for new clients, do market research, build and deliver product presentations, develop sales presentations and proposals'/>
        <Item_what_we_do  img={ImgBuilder.Diamond} text='We develop a deep understanding of the product, the market, and the customers’ needs'/>
        <Item_what_we_do  img={ImgBuilder.Graph} text='We partner closely with teams such as Supply and Product to deliver a world-class customer experience and maximize revenue'/>        
    </section>
  )
};
export default What_we_do;