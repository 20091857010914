import React from 'react';


import './style.scss';

const Item_what_we_do = ({ img, text}) => {
  return (
    <div className='item_what_we_do'> 
        <div className='img_con__what_we_do'>
            <img className='img__item_what_we_do' src={img} alt=" " />
        </div>
        <p className='p__item_what_we_do'>{text}</p>
    </div>
  )
};

export default Item_what_we_do;
