import React, { useState } from 'react';

import HeaderContent from '../../components/headerContent/headerContent';
import Front_slide from '../../components/front_slide/front_slide';
import About_us from '../../components/about_us/about_us';
import Footer from '../../components/footer/footer';
import Partners from '../../components/partners/partners';

import './style.scss';
// import ImgBuilder from "../../res/img/ImgBuilder";

const BasicPage = props => {
    return (
        <>
            <HeaderContent/>
            <Front_slide/>
            <About_us/>
            <Partners/>
            <Footer/>
        </>
    )
};

export default BasicPage;