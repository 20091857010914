import React from 'react';

import './style.scss';
import ImgBuilder from "../../res/img/ImgBuilder";
import What_we_do from '../what_we_do/what_we_do';

const About_us = () => {
  return (
    <>
        <div className='motto__about_us'>
            <p className='p__motto font_smaller'>We focus on strategic sales with big and small <span class='span_text'>Food and Drink</span> producers throughout Ukraine. We identify and articulate how we can unlock significant value for our customers.</p>
        </div>

        <section className='section_main_info' id="about_us">
          <div className='bg_img'>
            <img src={ImgBuilder.Office_2}  alt=" " />
          </div>
          <div className='one_half text_half'>

            <div class="text_main text_main_left">
              <div class="heading_section_text">
                <span class="head__heading_section_text">Discover</span>
                <h2 class="body__heading_section_text">Trade House EKOPRO</h2>
              </div>
              <div  className='con__p_text_main'>
                <ul>
                  <li className='p_text_main'>Created in 2009</li>
                  <li className='p_text_main'>Located in <a className='span_p_text_main a_text_main' href='https://goo.gl/maps/311mCpxTBSmh9TFw5'>Lviv, Ukraine</a></li>
                  <li className='p_text_main'>Area of business: <span className='span_p_text_main'>import, sale, and distribution of products for the Food and Drink industry</span></li>
                  <li className='p_text_main'>More than <span className='span_p_text_main'>30 active customers</span>  - we work with large multinational and national manufacturers as well as small family-owned local companies</li>
                  <li className='p_text_main'>Our team members are <span className='span_p_text_main'>experienced leaders, industry experts</span> with extensive experience in the food and beverage industry, and the highest caliber consultants. Our approach is professional, collaborative, and customized to our customer's unique needs and goals</li>
                  <li className='p_text_main'>Part of the business we run as a commission/agency company doing representative and marketing services </li>
                </ul>
              </div>
            </div>

          </div>  
        </section>
        


        <div className='motto__about_us' id="what_we_do">
            <p className='p__motto mbottom_0'>What we do</p>
        </div>
        <What_we_do/>


        <section className='section_main_info flex_end'>
          <div className='bg_img'>
            <img src={ImgBuilder.BgPhoto3}  alt=" " />
          </div>
          <div className='one_half text_half'>

            <div class="text_main">
              <div class="heading_section_text">
                <span class="head__heading_section_text">Discover</span>
                <h2 class="body__heading_section_text">Trade House Ekopro</h2>
              </div>
              <div  className='con__p_text_main'>
                <p className='p_text_main'>Our mission is to bring high-quality perspective and innovative products to the Ukrainian market for the economic prosperity of Ukraine.
                    In a highly competitive environment, we are constantly searching for products that can be in high demand in Ukraine regardless of whether it is a well-known brand or a promising start-up product.</p>
              </div>
            </div>

          </div>
        </section>
    </>
  )
};
export default About_us;