import React, {useLayoutEffect, useRef, useState, useEffect} from 'react';

import './style.scss';
import ImgBuilder from "../../res/img/ImgBuilder";

const Front_slide = () => {
    const [state, setState] = useState({
        perspective: "520px",
        scale: "1",
        transform: "rotateX(0deg) rotateY(0deg)",
    });
    const [MousePosition, setMousePosition] = useState({
        left: 0,
        top: 0
    })
    const ref = useRef(null);
    const handleMove = argument => { 
        const height = ref.current.clientHeight;
        const width = ref.current.clientWidth;
        const xVal = argument.nativeEvent.layerX;
        const yVal= argument.nativeEvent.layerY;
        // 20 is the answer why the rotion is so slow or fast
        const yRotation = 10 * ((xVal - width / 2) / width);
        const xRotation = -10 * ((yVal - height / 2) / height);

        setState({transform: ` perspective(${520}px) scale(1.05) rotateX(${xRotation}deg) rotateY(${yRotation}deg)`});
        setMousePosition({x: yRotation, y: xRotation});
    }
    const handleMoveOut = argument => {
        setState({perspective: `520px`, scale: '1', transform: `rotateX(0deg) rotateY(0deg)`});
    };
    


    return (
        <main className='main_front' id='main' >
            <div className='video_con'>
                <video src={ImgBuilder.Video} autoPlay muted loop className="bg_photo" type="video/mp4"/>
            </div>
            <div className='text_part__main'>
                
            
                <div className='p_container'>
                    <p className='p_main_name'>Trade House EKOPRO</p>
                    <p className='p_main_motoo'>Your reliable partner in Ukraine</p>
                </div>
            </div>

        </main>

    )
};
export default Front_slide;

        



