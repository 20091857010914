import Logo from './logo_ekopro.png';
import BgPhoto3 from './bg_photo3.jpg';
import Video from './video2.mp4';
import Ekoplast_Logo from './dpekoplast-3.png';
import SmartSeal_logo from './SmartSeal.png';
import FoodBase_Logo from './food_base_logo.svg';
import Office_2 from './office_2.png';
import Arrow from './arrow.svg';

import Guide from './temporary/guide.svg';
import Circle from './temporary/circle.svg';
import Diamond from './temporary/diamond.svg';
import Graph_circle from './temporary/graph_circle.svg';
import Graph from './temporary/graph.svg';
import Research from './temporary/research.svg';
import Sales from './temporary/sales.svg';





const ImgBuilder = {
    Logo,
    Arrow,
    Video,
    Ekoplast_Logo,
    SmartSeal_logo,
    FoodBase_Logo,
    BgPhoto3,
    Office_2,

    Guide,
    Circle,
    Diamond,
    Graph_circle,
    Graph,
    Research,
    Sales
}

export default ImgBuilder;