import React from 'react';

import './style.scss';
import ImgBuilder from "../../res/img/ImgBuilder";

const Partners = () => {

  return (
        <>
            <div className='motto__about_us'  id="partners" >
                <p className='p__motto'>Our long-term partners:</p>
            </div>
            <section className='partners'>
            <a href='http://www.dpekoplast.sk/en/' className='con_partner'>
                <img src={ImgBuilder.Ekoplast_Logo} className='partner'  alt=" " />
            </a>
            <a href='https://smartseal-closures.com/' className='con_partner'>
                <img id='partner_bigger_logo' src={ImgBuilder.SmartSeal_logo} className='partner'  alt=" " />
            </a>
            <a href='https://foodbase.hu/en/' className='con_partner'>
                <img src={ImgBuilder.FoodBase_Logo} className='partner'  alt=" " />
            </a>
            </section>
        </>
  )
};
export default Partners;