import React from 'react';

import './style.scss';
import ImgBuilder from "../../res/img/ImgBuilder";

const HeaderContent = () => {

  return (
    <header className='header__main {{display}}'  who_is_active='{{Active}}'>
      <div className='logo__header__main'>
        <img src={ImgBuilder.Logo} alt=' ' className='img__logo__header__main'/>
        <p className='p__logo__header__main'>
          Trade House EKOPRO
        </p>
      </div>
      <div className='menu__header__main'>
        <a href="#about_us" className='p__menu__header__main'>About us</a>
        <a href="#what_we_do" className='p__menu__header__main'>What we do</a>
        <a href="#partners" className='p__menu__header__main'>Partners</a>
        <a href="#contacts" className='p__menu__header__main'>Contacts</a>
      </div>
    </header>
  )
};
export default HeaderContent;